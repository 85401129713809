import classNames from 'classnames';

import Button from 'ui-kit/button/button';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import { CartIconProps } from 'ui-kit/icons/cart-icon/cart-icon.props';

import './empty-cart.style.scss';

export interface EmptyCartProps {
    cartColor?: CartIconProps['color'];
    onClickButton?: () => void;
    className?: string;
    title?: string;
    body: string;
    btnLabel: string;
}

const EmptyCart = ({ cartColor = 'blue', onClickButton, className, btnLabel, title, body }: EmptyCartProps) => {
    const classes = classNames('empty-cart', className);
    return (
        <div className="empty-cart-component">
            <div className={classes}>
                <CartIcon className={'cart-icon__circle'} color={cartColor} />
                <h2 className="empty-cart-title">{title}</h2>
                <h3 className="empty-cart-body">{body}</h3>
                <div className="spacer"></div>
                <Button
                    className="empty-cart-button"
                    onClick={onClickButton}
                    variant="primary"
                    type="button"
                    label={btnLabel}
                />
            </div>
        </div>
    );
};

export default EmptyCart;
